"use strict";

import "regulus-oscar";
import "./components/filter-form";
import "./components/catalogue_filters";

//sliders
$(function() {
  $('.slider').each(function(_, element) {
    const slider = $(element);
    const sliders = slider.find('.slider-container');

    slider.find('.slider-previous').click(function() {
      for (let i = 0; i < sliders.length; i++) {
        sliders[i].scrollBy({
          top: 0,
          left: -275,
          behavior: 'smooth'
        });
      }
    });

    slider.find('.slider-next').click(function() {
      for (let i = 0; i < sliders.length; i++) {
        sliders[i].scrollBy({
          top: 0,
          left: 275,
          behavior: 'smooth'
        });
      }
    });

  });
});
